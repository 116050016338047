import { useMemo } from "react";

import { graphql, useStaticQuery } from "gatsby";

const useCategoryImage = (category: string) => {
  const data = useStaticQuery(
    graphql`
      query {
        allFile(filter: { relativeDirectory: { eq: "categories" } }) {
          nodes {
            childImageSharp {
              gatsbyImageData(width: 200, height: 90)
            }
            relativePath
          }
        }
      }
    `
  );

  const image = data.allFile.nodes.find(
    (data: any) => data.relativePath === `categories/${category}.jpg`
  );

  return useMemo(
    () => ({
      image: image.childImageSharp.gatsbyImageData,
    }),

    [image.childImageSharp.gatsbyImageData]
  );
};

export default useCategoryImage;
