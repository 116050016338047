import React from "react";

import BaseSection from "components/Sections/BaseSection";

interface IPollSectionProps {
  title: string
}

const PollSection: React.FunctionComponent<IPollSectionProps> = ({ title, children }) => {
  
  return (
    <BaseSection title={title}>
      {children}
    </BaseSection>
  );
};

export default PollSection;
