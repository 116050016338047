import * as React from "react";

import { useTimer as _useTimer } from 'react-timer-hook';

export interface ITimerSettings {
  autoStart?: boolean;
  expiryTimestamp: Date;
  onExpire?: () => void;
}

function useTimer({...props}: ITimerSettings) {
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = _useTimer({ ...props });
  const expired = props.expiryTimestamp.valueOf() < new Date().getTime();
  return {
      seconds,
      minutes,
      hours,
      days,
      isRunning,
      expired,
      start,
      pause,
      resume,
      restart,
    }
}

export default useTimer;
