import React from "react";

import { UserResponse } from "api/user";
import ClientNode from "components/ClientNode";
import PrimaryButton from "components/common/Buttons/Primary";
import Paragraph from "components/common/Paragraph";
import Timer from "components/common/Time/Timer";
import VoteCount from "components/common/Vote/VoteCount";
import Avatar from "components/Icons/Avatar";
import { ShowIcon } from "components/Icons/ShowIcon";
import VoteIcon from "components/Icons/VoteIcon";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import useTimer from "hooks/common/useTimer";
import useCategoryImage from "hooks/polls/useCategoryImage";
import { Category, Poll } from "types/api";

interface IUserProps {
  displayName: string;
  photoURL: string;
}

interface IPollCardProps extends Poll {
  user: IUserProps;
  category: Category;
  deadline: Date;
}

const PollThumbnail: React.FunctionComponent<IPollCardProps> = ({
  id,
  category,
  title,
  deadline,
  user,
  options,
  ...props
}) => {
  const { image } = useCategoryImage(category);
  const { isRunning } = useTimer({
    expiryTimestamp: deadline,
  });
  const voteCount = options.reduce((prev, cur) => cur.voteCount + prev, 0)

  return (
    <div className="w-72 relative rounded-2xl shadow-md  flex flex-col justify-between flex-shrink-0 items-stretch overflow-hidden">
      {/* upper part */}
      <div className="w-full relative h-1/4 overflow-hidden">
        <GatsbyImage
          image={image}
          alt={`${category}`}
          className="w-full"
          imgStyle={{
            borderTopLeftRadius: "1rem",
            borderTopRightRadius: "1rem"
          }}
        />

        <h1 className="absolute text-white text-lg bottom-1 right-2 ">
          {category.toUpperCase()}
        </h1>
      </div>

      <div className="px-2 py-2 flex flex-col justify-between flex-1">
        <div className="flex flex-row items-center flex-shrink-0">
          <Avatar uri={user.photoURL} className="w-8 h-8 mr-3" />
          <span className="text-gray-500 italic font-thin">
            {user.displayName}
          </span>
        </div>

        {/* middle part break-words whitespace-nowrap overflow-hidden overflow-ellipsis */}
        <h2 className="paragraph w-full max-h-24 mx-auto text-base font-black text-gray-600">
          {title}
        </h2>

        {/* lower part */}
        <div className="flex items-center justify-between">
          <div className="flex flex-col pl-1 text-gray-100">
            <VoteCount value={voteCount}/>
            <h5 className="text-gray-500 text-xs font-thin">
              <ClientNode>
                <Timer expiryTimestamp={deadline} />
              </ClientNode>
            </h5>
          </div>
          <Link to={`/poll/detail/${encodeURI(id)}`}>
            <PrimaryButton
              className={
                isRunning
                  ? "w-14 h-14 p-3 rounded-full bg-secondary"
                  : "w-14 h-14 p-3 rounded-full bg-primary"
              }
            >
              {isRunning ? <VoteIcon className="fill-shades-0"/> : <ShowIcon />}
            </PrimaryButton>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PollThumbnail;
