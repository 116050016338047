import React, { useState } from "react";

import useTimer from "hooks/common/useTimer";

const DEFAULT_TEXT = "loading...";
const EXPIRED_TEXT = "The poll is ended";

interface ITimerProps extends Partial<HTMLHeadingElement> {
  expiryTimestamp: Date;
}

interface ITimeUnitProps {
  value: number;
  unit: string;
}

const unit = ({ value, unit }: ITimeUnitProps) =>
  value ? `${`${value} ${unit}${value === 1 ? "" : "s"}`}` : "";

const Timer: React.FunctionComponent<ITimerProps> = ({ expiryTimestamp, ...props }) => {
  const { isRunning, expired, seconds, minutes, hours, days } = useTimer({
    expiryTimestamp: expiryTimestamp,
  });

  return (
    <>
      {expired && !seconds && !minutes && !hours && !days && EXPIRED_TEXT}
      {!expired &&
        `${unit({ value: days, unit: "day" })}${
          !days ? unit({ value: hours, unit: "hour" }) : ""
        }${!days && !hours ? unit({ value: minutes, unit: "minutes" }) : ""}${
          !days && !hours && !minutes
            ? unit({ value: seconds, unit: "second" })
            : ""
        } Remaining`}
    </>
  );
};

export default Timer;
