import React from "react";

interface IVoteCount extends React.HTMLAttributes<HTMLHeadingElement>{
  value: number;
  unit?: string;
}

const DEFAULT_UNIT = 'vote'

const VoteCount: React.FunctionComponent<IVoteCount> = ({
  value,
  unit,
  ...props
}) => {
  const unitText = `${unit ?? DEFAULT_UNIT}${value >= 0 && value <= 1 ? 's' : ''}`;
  
  return (
    <h5 className={`text-gray-500 text-xs font-thin`} {...props}>
      {value >= 0 && value <= 999 ? `${value} ${unitText}`: null}
      {value >= 1000 && value <= 9999 ? `${Math.floor(value / 1000)}K+ ${unitText}` : null }
      {value >= 1000000 && value <= 9999999 ? `${Math.floor(value / 1000000)}M+ ${unitText}` : null}
    </h5>
  );
};

export default VoteCount;
