import React from "react";
import "./section.css";

export interface IBaseSectionProps {
  title?: string;
}

const BaseSection: React.FunctionComponent<IBaseSectionProps> = ({
  title,
  children,
}) => {
  return (
    <section className="section width-full h-auto my-2 overflow-hidden">
      <h3 className="w-full border-b">{title}</h3>
      <div className="w-full height-auto p-2 overflow-x-scroll flex gap-x-4">
        {children}
      </div>
    </section>
  );
};

export default BaseSection;
